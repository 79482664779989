const { default: toast } = require('react-hot-toast');
window.axios = require('axios').default;

// Add a request interceptor
window.axios.interceptors.request.use(config => {
    //
    config.withCredentials = true;
    // Do something before request is sent
    config.baseURL = process.env.REACT_APP_API;
    config.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    config.headers.common['Accept'] = 'application/json';
    config.headers.common['Content-Type'] = 'application/json';
    // Authorization token 
    if (window.localStorage.getItem('dscart_auth_token')) {
        config.headers.common['Authorization'] = window.localStorage.getItem('dscart_auth_token');
    }

    // locale
    if(window.localStorage.getItem('dscart_locale')){
        config.headers.common['Accept-Language'] = window.localStorage.getItem('dscart_locale');
    }
    
    // guest_id
    if(window.localStorage.getItem('dscart_guest_id')){
        config.headers.common['guest_id'] = window.localStorage.getItem('dscart_guest_id');
    }else{
        config.headers.common['guest_id'] = null;
    }
    
    return config;
});

// Add a response interceptor
window.axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    if(response && response.data && response.data.message){
        toast.success(response.data.message);
    }
    return response;
}, function (error) {
    const pathname = window.location.pathname;

    if (error.response === undefined) {
        toast.error('Api server not response');
        if (pathname.includes('/error') === false) {
            // window.location.replace('/error');
        }
    }else if([404, 500].includes(error.response.status)){
        toast.error(error.response.statusText);
    }else if(error.response.status === 401 && window.location.pathname !== '/logout'){
        document.location.href = 'http://localhost:3000/logout';
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
});
require('./bootstrap')
