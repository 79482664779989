import React, { createContext, useReducer, useContext, useEffect, useState } from "react";
import Auth from "./reducers/auth";
import AuthToken from "./reducers/auth_token";
import Locale from "./reducers/locale";
import Setting from './reducers/setting';

// initaial values
const initialState = {
    guestId: Auth.GuestId(),
    env: [],
    auth: Auth(),
    token: AuthToken(),
    cart: [],
    settings: Setting.Get(),
    locale: Locale.Get(),
    locales: [],
    menu: {},
    isOnline: window.navigator.onLine === true ? true : false,
    xhrError522: false,
};

/**
 * create context
 */
const Context = createContext([{}, () => { }]);

/**
 * 
 * @param {*} props 
 * @returns 
 */
function Provider(props) {

    const [init, setInit] = useState(false);

    const reducer = (state, action) => {

        switch (action.type) {
            case 'SET_ENV':
                return {
                    ...state,
                    env: action.env,
                };

            case 'SET_CART':
                return {
                    ...state,
                    cart: action.cart,
                };

            case 'AUTH_UPDATE':
                return {
                    ...state,
                    auth: Auth.Update(action.data),
                };
            case 'AUTH_LOGIN':
                return {
                    ...state,
                    auth: Auth.Login(action.data),
                };
            case 'AUTH_LOGOUT':
                return {
                    ...state,
                    auth: Auth.Logout(),
                    cart: [],
                    guestId: Auth.GuestId(true),
                };
            case 'AUTH_TOKEN_UPDATE':
                return {
                    ...state,
                    token: AuthToken.Update(action.token),
                };
            case 'UPDATE_STORAGE':
                return {
                    ...state,
                    guestId: Auth.GuestId(),
                    auth: Auth(),
                };
            case 'SETTING_UPDATE':

                if (!action.key && !action.value) {
                    return state;
                }
                if (state.settings[action.key] === undefined) {
                    return state;
                }

                state.settings[action.key] = action.value;
                return state;
            case 'SETTING_SET':
                return {
                    ...state,
                    settings: action.settings
                }
            case 'UPDATE_LANG':

                if (!action.key && !action.value) {
                    return state;
                }
                state.lang[action.key] = action.value;
                return state;
            case 'LANG_SET':
                return {
                    ...state,
                    lang: action.lang
                }
            case 'IS_ONLINE':
                return {
                    ...state,
                    isOnline: action.status,
                }
            case 'UPDATE_MENU':
                if (!action.key && !action.value) {
                    return state;
                }
                state.menu[action.key] = action.value;
                return state;
            case 'SET_MENU':
                return {
                    ...state,
                    menu: action.menus,
                }

            case 'XHR_ERROR_522':
                return {
                    ...state,
                    xhrError522: action.status,
                }
            case 'CZ':
                return {
                    ...state,
                    czModal: action.czModal
                }
            case 'CZ_CLOSE':
                return {
                    ...state,
                    czModal: {
                        open: false,
                        keys: "",
                        title: "",
                    }
                }
            case 'UPDATE_CUSTOMIZEABLE':
                return {
                    ...state,
                    customizeable: action.status
                }

            case 'CURRENT_LOCALE_SET':
                return {
                    ...state,
                    locale: Locale.Set(action.locale),
                }

            case 'LOCALES_SET':
                return {
                    ...state,
                    locales: action.locales,
                }

            default:
                return state;
        }
    }

    const [store, dispatch] = useReducer(reducer, initialState);

    /**
     * 
     * @param {*} e 
     */
    const handleStorageUpdate = (e) => {

        const db = ['dscart_cart', 'dscart_guest_id', 'dscart_auth', 'dslocale'];

        if (db.includes(e.key)) {
            dispatch({
                type: 'UPDATE_STORAGE',
                key: e.key,
                newValue: e.newValue
            });
        }
    }

    /**
     * 
     */
    useEffect(() => {
        window.addEventListener("storage", handleStorageUpdate);

        return () => window.removeEventListener("storage", handleStorageUpdate);

    }, []);

    /**
     * Navigator.onLine
     * Navigator.offLine
     * @return boolean
     */
    const handleNetworkChange = (e) => {

        dispatch({
            type: 'IS_ONLINE',
            status: window.navigator.onLine === true ? true : false,
        });
    }

    // online offline
    useEffect(() => {
        window.addEventListener('online', handleNetworkChange);
        window.addEventListener('offline', handleNetworkChange);

        return () => {
            window.removeEventListener('online', handleNetworkChange);
            window.removeEventListener('offline', handleNetworkChange);
        };

    }, []);


    /**
     * get data from server
     */
    useEffect(() => {

        if (init === false) {

            let url = '/locales';
            const currentLocale = window.localStorage.getItem('dscart_locale');
            if (currentLocale) {
                url = '/locales?locale=' + currentLocale;
            }

            window.axios.get(url).then(r => {
                if (r.data) {

                    dispatch({
                        type: 'LOCALES_SET',
                        locales: r.data.locales,
                    });

                    // set current language
                    if(currentLocale === undefined){
                        dispatch({
                            type: 'CURRENT_LOCALE_SET',
                            locale: r.data.current,
                        });
                    }
                }
            }).catch(error => {
                // console.log('error', error);
                if (error.response === undefined) {

                }
            });

            window.axios.get('/settings').then(r => {

                if (r.data) {
                    dispatch({
                        type: 'SETTING_SET',
                        settings: r.data,
                    })
                }

            }).catch(error => {
                // console.log('error', error);
                if (error.response === undefined) {

                }
            });

            window.axios.get('/menus').then(r => {
                if (r.data) {
                    dispatch({
                        type: 'SET_MENU',
                        menus: r.data,
                    })
                }
            }).catch(error => {
                // console.log('error', error);
                if (error.response === undefined) {

                }
            });

            window.axios.get('/cart').then(r => {

                if (r.data) {
                    dispatch({
                        type: 'SET_CART',
                        cart: r.data,
                    })
                }

            }).catch(error => { });

            window.axios.get('/env').then(r => {
                if (r.data) {
                    dispatch({
                        type: 'SET_ENV',
                        env: r.data,
                    })
                }
            }).catch(error => { });
        }

        setInit(true);
    }, [init]);


    return (
        <Context.Provider value={{ store, dispatch }}>
            {props.children ? props.children : null}
        </Context.Provider>
    )
}

/**
 * get full store;
 * @returns 
 */

export function useStore() {

    return useContext(Context);
}

/**
 * 
 * @returns 
 */
export function useSetting() {
    const { store, dispatch } = useStore();
    return { settings: store.settings, dispatch };
}


/**
 * 
 * @returns 
 */
export function useEnv() {
    const { store } = useStore();
    return store.env;
}

/**
 * 
 * @returns 
 */
export function useCz() {
    const { store, dispatch } = useStore();
    return { cz: store.czModal, dispatch };
}

/**
 * 
 * @returns 
 */
export function useAuth() {
    const { store, dispatch } = useStore();

    // check isGuest
    var isGuest = function () {

        if (store.auth && store.auth.check) {
            return false;
        }

        return true;
    }

    // check isAdmin
    var isAdmin = function () {
        if (store.auth.check && store.auth.role === 'admin') {
            return true;
        }
        return false;
    }

    // check isUser
    const isUser = function () {
        if (store.auth.check && store.auth.role === 'user') {
            return true;
        }
        return false;
    }

    return {
        auth: store.auth,
        dispatch,
        isAdmin: isAdmin(),
        isUser: isUser(),
        isGuest: isGuest(),
    };
}

/**
 * Navigator
 * @returns []
 */
export function useNavigator() {
    const { store, dispatch } = useStore();

    return { Online: store.isOnline, Offline: !store.isOnline, dispatch };
}

/**
 * 
 * @returns 
 */
export function usePage() {
    const { store, dispatch } = useStore();
    return { pages: store.pages, dispatch };
}

/**
 * 
 */
export {
    Provider as AppProvider,
    Context as AppContext,
};

export default Provider;