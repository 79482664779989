import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { styled } from "goober";
import { AppProvider } from './provides/app';
import reportWebVitals from './reportWebVitals';
import ItemProvider from './provides/Item';
import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';
import './sass/index.scss';

const App = React.lazy(() => import('./App')); // Lazy-loaded

const Spinner = () => {
  const Div = styled('div')`
    height: 90vh !important;
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    border: 0 !important;
  `;

  return (
    <Div>
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </Div>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <AppProvider>
      <I18nextProvider i18n={i18n}>
        <ItemProvider>
          <Suspense fallback={<Spinner />}>
            <App />
          </Suspense>
        </ItemProvider>
      </I18nextProvider>
    </AppProvider>
  </React.StrictMode>,
  document.getElementById('app')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
